const BufferflyGroupsList = [
  [
    { group: 'ddlG', partname: 'Grille', partid: 'G' },
    { group: 'ddlG', partname: 'Radiator', partid: 'RA' },
    { group: 'ddlG', partname: 'Radiator Support', partid: 'RS' }
  ],
  [{ group: 'ddlFP', partname: 'Front Apron', partid: 'FP' }],
  [{ group: 'ddlLFL', partname: 'Front Lamps (left)', partid: 'LFL' }],
  [{ group: 'ddlRFL', partname: 'Front Lamps (right)', partid: 'RFL' }],
  [{ group: 'ddlFB', partname: 'Front Bumper Bar', partid: 'FB' }],
  [
    { group: 'ddlB', partname: 'Bonnet', partid: 'B' },
    { group: 'ddlB', partname: 'Air Conditioning', partid: 'AC' },
    { group: 'ddlB', partname: 'Engine Bay Parts', partid: 'EBH' },
    { group: 'ddlB', partname: 'Front Chassis', partid: 'CF' },
    { group: 'ddlB', partname: 'Front Suspension, Steering & Wheels', partid: 'FM' },
    { group: 'ddlB', partname: 'Engine', partid: 'E' },
    { group: 'ddlB', partname: 'Decal', partid: 'OL' }
  ],
  [
    { group: 'ddlBL', partname: 'Boot Lid', partid: 'BL' },
    { group: 'ddlBL', partname: 'Exhaust', partid: 'EXH' },
    { group: 'ddlBL', partname: 'Boot Floor', partid: 'BF' },
    { group: 'ddlBL', partname: 'Rear Suspension, Tank & Wheels', partid: 'RM' }
  ],
  [
    { group: 'ddlFW', partname: 'Front Windscreen', partid: 'FW' },
    { group: 'ddlFW', partname: 'Wiper', partid: 'W' }
  ],
  [
    { group: 'ddlLCP', partname: 'Centre Pillar (left)', partid: 'LCP' },
    { group: 'ddlLCP', partname: 'Side Panel (left)', partid: 'LBP' },
    { group: 'ddlLCP', partname: 'Sliding Door (left)', partid: 'LSD' }
  ],
  [{ group: 'ddlLFD', partname: 'Front Door (left)', partid: 'LFD' }],
  [{ group: 'ddlLFG', partname: 'Front Guard (left)', partid: 'LFG' }],
  [{ group: 'ddlLFH', partname: 'Front Pillar (left)', partid: 'LFH' }],
  [{ group: 'ddlLQP', partname: 'Rear Qtr Panel (left)', partid: 'LQP' }],
  [{ group: 'ddlLRD', partname: 'Rear Door (left)', partid: 'LRD' }],
  [{ group: 'ddlLSP', partname: 'Sill Panel (left)', partid: 'LSP' }],
  [{ group: 'ddlRB', partname: 'Rear Bumper Bar', partid: 'RB' }],
  [
    { group: 'ddlRCP', partname: 'Centre Pillar (right)', partid: 'RCP' },
    { group: 'ddlRCP', partname: 'Side Panel (right)', partid: 'RBP' },
    { group: 'ddlRCP', partname: 'Sliding Door (right)', partid: 'RSD' }
  ],
  [{ group: 'ddlRFD', partname: 'Front Door (right)', partid: 'RFD' }],
  [{ group: 'ddlRFG', partname: 'Front Guard (right)', partid: 'RFG' }],
  [{ group: 'ddlRFH', partname: 'Front Pillar (right)', partid: 'RFH' }],
  [{ group: 'ddlLRL', partname: 'Rear Lamps (left)', partid: 'RL' }],
  [{ group: 'ddlRRL', partname: 'Rear Lamps (right)', partid: 'RL' }],
  [{ group: 'ddlRNP', partname: 'Rear Licence Plate', partid: 'RNP' }],
  [{ group: 'ddlBP', partname: 'Beaver Panel', partid: 'BP' }],
  [{ group: 'ddlRQP', partname: 'Rear Qtr Panel (right)', partid: 'RQP' }],
  [{ group: 'ddlRRD', partname: 'Rear Door (right)', partid: 'RRD' }],
  [{ group: 'ddlRSP', partname: 'Sill Panel (right)', partid: 'RSP' }],
  [
    { group: 'ddlSP', partname: 'Plenum & Firewall', partid: 'SP' },
    { group: 'ddlSP', partname: 'Dash & Glove Box', partid: 'D' }
  ],
  [
    { group: 'ddlT', partname: 'Turret', partid: 'T' },
    { group: 'ddlT', partname: 'Interior Trims', partid: 'I' },
    { group: 'ddlT', partname: 'Floor Panel', partid: 'FPAN' },
    { group: 'ddlT', partname: 'Chassis Assembly', partid: 'FC' },
    { group: 'ddlT', partname: 'Antenna', partid: 'AE' }
  ],
  [{ group: 'ddlTG', partname: 'Tailgate', partid: 'TG' }],
  [{ group: 'ddlRW', partname: 'Rear Windscreen', partid: 'RW' }]
]

export default BufferflyGroupsList