const ButterflyGroups = [
  {
    name: 'ddlG',
    coords: '131,456,167,456,167,440,127,440',
    label: 'Grille\nRadiator\nRadiator Support'
  },
  {
    name: 'ddlFP',
    coords: '195,476,103,476,95,468,199,468',
    label: 'Front Apron'
  },
  {
    name: 'ddlLFL',
    coords: '171,452,175,440,195,440,195,452',
    label: 'Front Lamps (left)'
  },
  {
    name: 'ddlRFL',
    coords: '124,452,100,452,104,440,120,440',
    label: 'Front Lamps (right)'
  },
  {
    name: 'ddlFB',
    coords: '93,459,95,468,98,469,135,470,134,462,162,462,163,469,201,469,201,458',
    label: 'Front Bumper Bar'
  },
  {
    name: 'ddlB',
    coords: '107,308,151,312,191,308,191,364,191,368,163,372,131,372,111,368',
    label: 'Bonnet\nAir Conditioning\nEngine Bay Parts\nFront Chassis\nFront Suspension, Steering & Wheels\nEngine\nDecal'
  },
  {
    name: 'ddlBL',
    coords: '112,144,188,144,188,112,112,112',
    label: 'Boot Lid\nExhaust\nBoot Floor\nRear Suspension, Tank & Wheels'
  },
  {
    name: 'ddlFW',
    coords: '152,403,184,403,192,431,148,431,104,431,112,403',
    label: 'Front Windscreen\nWiper'
  },
  {
    name: 'ddlLCP',
    coords: '211,227,267,227, 267, 237, 211,237',
    label: 'Centre Pillar (left)\nSide Panel (left)\nSliding Door (left)'
  },
  {
    name: 'ddlLFD',
    coords: '267,239,267,299,259,299,235,291,211,263,211,239',
    label: 'Front Door (left)'
  },
  {
    name: 'ddlLFG',
    coords: '234,290,235,326,240,376,245,381,244,371,259,370,258,354,253,347,250,335,251,325,253,317,258,311,264,306,276,302,262,298,262,300,257,300',
    label: 'Front Guard (left)'
  },
  {
    name: 'ddlLFH',
    coords: '211,263,207,271,223,295,235,311,235,291',
    label: 'Front Pillar (left)'
  },
  {
    name: 'ddlLQP',
    coords: '235,171,251,171,251,155,259,143,259,107,255,111,255,119,243,123,239,123,239,115,235,115,235,163',
    label: 'Rear Qtr Panel (left)'
  },
  {
    name: 'ddlLRD',
    coords: '211,227,211,191,219,179,235,171,251,171,255,183,267,191,267,227',
    label: 'Rear Door (left)'
  },
  {
    name: 'ddlLSP',
    coords: '275,195,275,299,267,299,267,191',
    label: 'Sill Panel (left)'
  },
  {
    name: 'ddlRB',
    coords: '95,40,203,40, 203, 30, 95, 30',
    label: 'Rear Bumper Bar'
  },
  {
    name: 'ddlRCP',
    coords: '31,228,87,228, 87, 238, 31, 238',
    label: 'Centre Pillar (right)\nSide Panel (right)\nSliding Door (right)'
  },
  {
    name: 'ddlRFD',
    coords: '87,264,67,288,47,296,31,300,31,236,87,236',
    label: 'Front Door (right)'
  },
  {
    name: 'ddlRFG',
    coords: '40,371,56,367,56,379,60,379,64,351,64,319,64,291,44,299,24,299,24,303,36,307,44,315,52,331,48,343,40,351',
    label: 'Front Guard (right)'
  },
  {
    name: 'ddlRFH',
    coords: '66,289,66,309,94,270,88,264',
    label: 'Front Pillar (right)'
  },
  {
    name: 'ddlLRL',
    coords: '179,48,179,58,202,58,201,48',
    label: 'Rear Lamps (left)'
  },
  {
    name: 'ddlRRL',
    coords: '94,48,95,57,117,57,117,48',
    label: 'Rear Lamps (right)'
  },
  {
    name: 'ddlRNP',
    coords: '137,49,138,57,162,57,162,49',
    label: 'Rear Licence Plate'
  },
  {
    name: 'ddlBP',
    coords: '95,41,94,47,116,47,117,58,97,58,99,65,196,66,199,58,178,57,178,47,201,45,201,43',
    label: 'Beaver Panel'
  },
  {
    name: 'ddlRQP',
    coords: '64,115,64,139,64,167,64,171,48,171,52,163,48,155,40,143,40,107,48,111,44,119,56,123,56,111',
    label: 'Rear Qtr Panel (right)'
  },
  {
    name: 'ddlRRD',
    coords: '32,227,88,227,88,191,80,179,64,171,48,171,48,179,44,183,32,191',
    label: 'Rear Door (right)'
  },
  {
    name: 'ddlRSP',
    coords: '31,299,23,299,23,195,31,191',
    label: 'Sill Panel (right)'
  },
  {
    name: 'ddlSP',
    coords: '106,298,109,311,192,310,195,296,179,300,158,302,144,302',
    label: 'Plenum & Firewall\nDash & Glove Box'
  },
  {
    name: 'ddlT',
    coords: '119,263,179,263,183,259,183,187,151,183,115,187',
    label: 'Turret\nInterior Trims\nFloor Panal\nChassis Assembly\nAntenna'
  },
  {
    name: 'ddlTG',
    coords: '115,179,107,159,111,155,147,151,191,155,191,167,183,179,191,179,199,171,199,119,187,115,187,143,111,143,111,115,103,119,103,171,107,179',
    label: 'Tailgate'
  },
  {
    name: 'ddlRW',
    coords: '107,71,115,91,119,95,151,95,179,95,183,91,191,71,187,71,147,71',
    label: 'Rear Windscreen'
  }
]

export default ButterflyGroups
